@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* global.css */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.animate-slide-in-right {
  animation: slide-in-right 1s ease-in-out;
}

.clip-custom {
    clip-path: polygon(15% 0%, 90% 0%, 85% 0%, 100% 100%, 0% 100%, 0% 95%);
}

.clip-customHormonal {
    clip-path: polygon(15% 0%, 90% 0%, 85% 0%, 100% 100%, 0% 100%, 0% 95%);
}

.clip-customFertilite {
    clip-path: polygon(27% 0%, 90% 0%, 70% 0%, 100% 100%, 0% 100%, 0% 95%);
}

.clip-customWeightLoss {
    clip-path: polygon(17% 0%, 90% 0%, 80% 0%, 100% 100%, 0% 100%, 0% 95%);
}

.font-handwritten {
    font-family: 'Indie Flower';
}

h1, h2, h3, h4 {
  font-family: 'Merriweather', sans-serif;
  color: #94255a;
}

p, li {
  color: #2D2D2D;
}

.hidden {
    display: none;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

